'use strict';

import Glide from '@glidejs/glide';


$('.slider').each(function(){
  var sliderID = $(this).attr('id');
  var glide = new Glide('#'+sliderID, {
    type: 'carousel',
    perView: 3,
    breakpoints: {
      980: {
        perView: 2
      },
      660: {
        perView: 1
      }
    }
  });

  if ($('#'+sliderID).length) {
    glide.mount();
  }

});
